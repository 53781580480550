import { LanguageIsoCode } from 'enums/language'
import { UserRight, UserRole } from 'enums/user-role'
import { ContributorModel } from 'models/contributor.model'
import { Contributor } from './contributors'

export class User extends Contributor {
    private languageIsoCodes: LanguageIsoCode[] = []
    private roles: UserRole[]
    private rights: UserRight[]
    private restrictedFeatures: string[]

    constructor(contributor?: ContributorModel) {
        super(contributor)
        if (!this.acl) {
            this.acl = {
                languages: [],
                roles: [],
                rights: [],
                restrictedFeatures: [],
            }
        }
    }

    setRoles(roles: UserRole[]): User {
        this.roles = roles

        return this
    }

    getRoles(): UserRole[] {
        return [...this.roles]
    }

    setLanguageIsoCodes(languageIsoCodes: LanguageIsoCode[]): User {
        this.languageIsoCodes = languageIsoCodes

        return this
    }

    setRights(rights: UserRight[]) {
        this.rights = rights

        return this
    }

    setRestrictedFeatures(restrictedFeatures: string[]) {
        this.restrictedFeatures = restrictedFeatures

        return this
    }

    getLanguageIsoCodes(): LanguageIsoCode[] {
        return this.languageIsoCodes
    }

    getDefaultLanguageIsoCode(): LanguageIsoCode {
        return this.getWrittenLanguageValid() || this.languageIsoCodes[0] || LanguageIsoCode.ENGLISH
    }

    /**
     * Get user written Language if present in DB
     */
    getWrittenLanguageValid(): LanguageIsoCode | undefined {
        if (this.writtenLanguage?.isoCode && this.languageIsoCodes.includes(this.writtenLanguage.isoCode)) {
            return this.writtenLanguage.isoCode
        }
    }

    /**
     * Verify if user has the given role ability
     */
    hasRole(role: UserRole): boolean {
        return this.roles.includes(role)
    }

    /**
     * Verify if user as the given permission
     */
    hasRight(right: UserRight): boolean {
        if (this.hasRole(UserRole.DEVELOPER)) {
            return true
        }

        return this.rights?.includes(right) || false
    }

    hasAccessToFeature(feature: string): boolean {
        return !this.restrictedFeatures.includes(feature)
    }

    isMultilingual(): boolean {
        return this.languageIsoCodes.length > 1
    }

    /**
     * Verify if user belongs to the given language team
     */
    hasLanguage(isoCode: LanguageIsoCode) {
        return this.languageIsoCodes.includes(isoCode)
    }
}
