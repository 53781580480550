import { DOCUMENT } from '@angular/common'
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { debounceTime, pluck, tap } from 'rxjs/operators'
import { AuthService } from 'services/auth/auth'
import { Feature } from '../../interfaces/feature'
import { networkQuery } from '../../tools/apollo'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { feature, features, updateFeatureMutation } from './feature.query'

@Injectable({
    providedIn: 'root',
})
export class FeatureService extends BaseService implements OnDestroy {
    public refresh = new Subject()
    private observer: MutationObserver
    private observerSubject: BehaviorSubject<HTMLElement[]> = new BehaviorSubject([])
    private renderer: Renderer2

    constructor(
        apollo: Apollo,
        protected errorsMessagesService: ErrorsMessagesService,
        private authService: AuthService,
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super(apollo, errorsMessagesService)
        this.renderer = this.rendererFactory.createRenderer(null, null)
    }

    public ngOnDestroy() {
        if (this.observerSubject) {
            this.observerSubject.unsubscribe()
        }
        if (this.observer) {
            this.observer.disconnect()
        }
    }

    public init(): void {
        this.setupFeatureElementsCleaner()
    }

    public getFeatures(): Observable<Feature[]> {
        return networkQuery<Feature[]>(
            this.apollo,
            {
                query: features,
            },
            ['data', 'features'],
        )
    }

    public getFeature(slug: string): Observable<Feature | undefined> {
        return networkQuery<Feature>(
            this.apollo,
            {
                query: feature,
                variables: {
                    slug,
                },
            },
            ['data', 'feature'],
        )
    }

    public updateFeature(feature: Feature): Observable<Feature> {
        return this.apollo
            .mutate({
                mutation: updateFeatureMutation,
                variables: { feature },
            })
            .pipe(pluck('data', 'updateFeature'))
    }

    private startObserver() {
        this.observer.observe(this.document.body, {
            childList: true,
            subtree: true,
        })
    }

    private setupFeatureElementsCleaner() {
        this.observer = new MutationObserver(() => {
            // Find all relevant elements and push them to the BehaviorSubject
            const elements = Array.from(this.document.querySelectorAll('[href], [feature]')) as HTMLElement[]
            this.observerSubject.next(elements)
        })

        this.observerSubject
            .pipe(
                tap(elements => {
                    // Disconnect observer to avoid infinite loop
                    this.observer.disconnect()
                    this.cleanFeatureElements(elements)
                }),
                debounceTime(1), // Debounce to prevent rapid triggering
                tap(() => {
                    // Reconnect observer
                    this.startObserver()
                }),
            )
            .subscribe()

        this.startObserver()
    }

    private isRestrictedFeature(name: string): boolean {
        const userRestrictedFeatures = this.authService.currentUser?.acl?.restrictedFeatures || []

        return userRestrictedFeatures.includes(name)
    }

    private cleanFeatureElements(elements: HTMLElement[]) {
        elements.forEach((element: HTMLElement) => {
            const feature = element.getAttribute('href') || element.getAttribute('feature')

            if (this.isRestrictedFeature(feature!)) {
                this.renderer.removeChild(element.parentElement, element)
            }
        })
    }
}
