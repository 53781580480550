import { Injectable } from '@angular/core'
import { DaletVersionId } from 'enums/dalet'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Story } from '../../entities/story'
import { IconMenuStatus } from '../../enums/icon-menu-status'
import { IconMenuLink } from '../../interfaces/icon-menu-link'
import { AuthService } from '../auth/auth'
import { StoryService } from './story'

const ROUTES: IconMenuLink[] = [
    {
        text: 'Title & Settings',
        link: 'titles-and-settings',
        icon: 'note-edit',
        status: IconMenuStatus.Neutral,
    },
    {
        text: 'Main header',
        link: 'main-header',
        icon: 'picture',
        status: IconMenuStatus.Neutral,
        separator: true,
    },
    {
        text: 'Video upload',
        link: 'upload-video',
        icon: 'two-way-arrows',
        status: IconMenuStatus.None,
        separator: true,
    },
    {
        text: 'Tags & Metadata',
        icon: 'tags',
        link: 'tags-and-metadata',
        status: IconMenuStatus.Neutral,
    },
    {
        text: 'You might also like',
        icon: 'organization',
        link: 'you-might-also-like',
        status: IconMenuStatus.Neutral,
    },
    {
        text: 'Contributors',
        icon: 'users',
        link: 'contributors',
        separator: true,
        status: IconMenuStatus.None,
    },
    {
        icon: 'language',
        text: 'Content localisation',
        link: 'translate',
        separator: true,
        status: IconMenuStatus.Disabled,
    },
    {
        icon: 'history',
        text: 'Workflow',
        link: 'workflow',
        status: IconMenuStatus.None,
    },
    {
        icon: 'bullet-list',
        text: 'Logs',
        link: 'logs',
        status: IconMenuStatus.None,
    },
]

@Injectable()
export class StoryMenuService {
    _routes: BehaviorSubject<IconMenuLink[]>
    public routes: Observable<IconMenuLink[]>

    constructor(protected storyService: StoryService, protected auth: AuthService) {
        this._routes = new BehaviorSubject(ROUTES)

        this.routes = this._routes.asObservable().pipe(
            map(routeList => {
                return routeList.map(route => {
                    route.status = route.status || IconMenuStatus.Neutral
                    route.active = route.active || 'active'

                    return route
                })
            }),
        )

        this.storyService.story.subscribe(story => this.updateStatus(new Story(story)))
    }

    /**
     * Check story to change the status of icon-link
     *
     * @param story
     */
    updateStatus(story: Story): void {
        const routes = [...ROUTES]

        const checks = {
            'main-header': 'checkMainHeader',
            'upload-video': 'checkVideoUpload',
            'you-might-also-like': 'checkRelatedArticles',
            'tags-and-metadata': 'checkTagsAndMetadata',
            'titles-and-settings': 'checkTitleAndSettings',
            'contributors': 'checkContributors',
            'translate': 'checkTranslate',
        }

        // TODO: Remove after the migration to Pyramid
        if (story.event.daletVersionId === DaletVersionId.GALAXY) {
            checks['send-to-publication'] = 'checkSendToPublication'
            checks['offline'] = 'checkOffline'

            routes.push(
                {
                    icon: 'upload-cloud',
                    text: 'Send to publication',
                    link: 'send-to-publication',
                    separator: true,
                    status: IconMenuStatus.Disabled,
                },
                {
                    icon: 'uninstall',
                    text: 'Take story offline',
                    link: 'offline',
                    status: IconMenuStatus.Disabled,
                },
            )
        }

        const canBeEdited: boolean = story.canBeEditedByUser(this.auth)
        const newRoutes = routes.map(route => {
            let status = route.status
            const method = checks[route.link]

            // Removing the 'offline' modal from the checks
            if (!canBeEdited && route.link !== 'offline') {
                status = IconMenuStatus.Disabled
            } else if (method !== undefined) {
                status = story[method]()
            }

            return Object.assign({}, route, { status })
        })

        this._routes.next(newRoutes)
    }
}
