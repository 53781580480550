import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { ContributorGetNamePipe } from 'pipes/contributor'
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { BaseService } from 'services/base'
import { ErrorsMessagesService } from 'services/errors-messages/errors-messages.service'
import { LanguagesService } from 'services/languages/languages'
import { User } from '../../entities/user'
import { UserRight, UserRole } from '../../enums/user-role'
import { environment } from '../../environments/environment'
import { UserModel } from '../../models/user.model'
import { ContributorService } from '../contributors/one'

@Injectable()
export class AuthService extends BaseService {
    isAuthentified: boolean = false

    /**
     * @todo: should be replaced by me props
     */
    currentUser?: User

    protected _user: BehaviorSubject<User> = new BehaviorSubject({} as User)
    public me = this._user.asObservable()

    private routeAuthorizationCache: Record<string, boolean> = {}

    constructor(
        apollo: Apollo,
        errorsMessagesService: ErrorsMessagesService,
        private http: HttpClient,
        private contributorService: ContributorService,
        private contributorGetNamePipe: ContributorGetNamePipe,
        private languagesService: LanguagesService,
    ) {
        super(apollo, errorsMessagesService)
    }

    public login(login: string, password: string): Observable<HttpResponse<object>> {
        const data = {
            username: login,
            password,
        }

        // allow http to set cookies

        return this.http
            .post(`${environment.mercury.baseUrlREST}/login`, data, {
                withCredentials: true,
                observe: 'response',
            })
            .pipe(
                map((res: HttpResponse<object>) => {
                    if (res.status === 200) {
                        this.isAuthentified = true
                    }

                    return res
                }),
            )
    }

    public logout(): Observable<HttpResponse<object>> {
        return this.http
            .post(
                `${environment.mercury.baseUrlREST}/logout`,
                {},
                {
                    withCredentials: true,
                    observe: 'response',
                },
            )
            .pipe(
                map((res: HttpResponse<object>) => {
                    if (res.status === 200) {
                        this.resetUser()
                        this.apollo.getClient().clearStore()
                    }

                    return res
                }),
            )
    }

    /**
     *
     */
    protected resetUser(): void {
        this.isAuthentified = false
        this.routeAuthorizationCache = {}
        delete this.currentUser
    }

    /**
     *
     * @returns boolean
     */
    async isAuth() {
        if (this.isAuthentified) {
            return true
        }

        // check if user is already auth through mercury
        return await this.getContributor()
    }

    getCurrentUser(): Observable<boolean> {
        return this.contributorService.me().pipe(
            mergeMap((contributor: UserModel) => {
                this.currentUser = new User(contributor)
                // @todo use the data provided by api to set role, languages and acl
                this.currentUser
                    .setLanguageIsoCodes(contributor.acl.languages)
                    .setRoles(contributor.acl.roles)
                    .setRights(contributor.acl.rights)
                    .setRestrictedFeatures(contributor.acl.restrictedFeatures)

                // update Subject
                this._user.next(this.currentUser)

                return observableOf(true)
            }),
        )
    }

    async getContributor() {
        const mePromise = this.getCurrentUser().toPromise()

        return await mePromise
            .then(() => {
                this.isAuthentified = true
            })
            .catch(() => {
                this.isAuthentified = false
            })
            .then(() => {
                return this.isAuthentified
            })
    }

    /**
     * @description Check if user has rights to
     * @param {Array<UserRight>} rights
     * @return {boolean}
     */
    public userHasRight(right: UserRight): boolean {
        if (!this.currentUser) {
            return false
        }

        return this.currentUser.hasRight(right)
    }

    /**
     * @description Check if user has role
     * @param {Array<UserRole>} UserRole
     * @return {boolean}
     */
    public userHasRole(role: UserRole): boolean {
        if (!this.currentUser) {
            return false
        }

        return this.currentUser.hasRole(role)
    }

    public userHasAccess(acl: { right?: UserRight; role?: UserRole; feature?: string }): boolean {
        if (!this.currentUser) {
            return false
        }

        const elementNotControlled = !acl.role && !acl.right
        const userHasAccessByRole = !!acl.role && this.currentUser.hasRole(acl.role)
        const userHasAccessByRight = !!acl.right && this.currentUser.hasRight(acl.right)
        const userHasAccessToFeature = !acl.feature || this.currentUser.hasAccessToFeature(acl.feature)

        return (elementNotControlled || userHasAccessByRole || userHasAccessByRight) && userHasAccessToFeature
    }

    /**
     * Get the user default language
     */
    public getUserDefaultLanguageIsoCode(): string {
        if (!this.currentUser) {
            return this.languagesService.defaultLanguage.isoCode
        }

        return this.currentUser.getDefaultLanguageIsoCode()
    }

    public getUserDefaultLanguageId(): number {
        return (
            this.languagesService.languageByIsoCode[this.getUserDefaultLanguageIsoCode()]?.id ||
            this.languagesService.defaultLanguage.id
        )
    }

    /**
     * Check current user against a userId
     * @param userId
     */
    public isCurrentUser(userId: number): boolean {
        if (!this.currentUser) {
            return false
        }

        return userId === this.currentUser.id
    }

    /**
     * Retrun the contributor name of the current user
     */
    public getUserContributorName(): string {
        if (!this.currentUser) {
            return 'unidentified user'
        }

        return this.contributorGetNamePipe.transform(this.currentUser)
    }

    public addRouteAuthorizationToCache(url: string, authorized: boolean): void {
        this.routeAuthorizationCache[url] = authorized
    }

    public getRouteAuthorizationFromCache(url: string): boolean | undefined {
        return this.routeAuthorizationCache[url]
    }
}
