export const cmsUserFragment = `
fragment contributorUserInfo on Contributor {
    id,
    slug,
    imageId,
    twitterScreenName
    isActive
    isImageActive
    isBioActive
    writtenLanguage{
        ...languageInfo
    }
    department {
        id
        description
        translations {
            title
            language{
                ...languageInfo
            }
        }
    }
    job {
        id
        description
        translations {
            title
            language{
                ...languageInfo
            }
        }
    }
    translations {
        slug
        contributorId
        surname
        forename
        country
        biography
        onlineDescription
        language {
            ...languageInfo
        }
    }
    acl {
        languages
        roles
        rights
        restrictedFeatures
    }
}
`
