<section class="c-searchbox">
    <form
        class="clr-form"
        (ngSubmit)="search()"
        [ngClass]="{ 'c-searchbox__form': true, 'c-searchbox--closed': !showAdvancedOptions }"
        [formGroup]="searchForm">
        <!-- SEARCH BOX -->
        <div
            class="c-searchbox__form u-display--foreground"
            [ngClass]="{ 'c-searchbox__show-popup': showAdvancedOptions }">
            <clr-signpost *ngIf="showSignpost">
                <button class="btn btn-link btn-icon" clrSignpostTrigger type="button">
                    <clr-icon shape="info" size="22" class="c-searchbox__button--icon"></clr-icon>
                </button>
                <clr-signpost-content class="signpost-content" clrPosition="bottom-right" *clrIfOpen>
                    <ng-container *ngIf="defaultSignpost; else content">
                        <strong>You can search by :</strong>
                        <ul>
                            <li>Dalet event ID</li>
                            <li>Cms event ID</li>
                            <li>Cms story ID</li>
                            <li>Housenumber video or story or event</li>
                            <li>Event name (from DALET)</li>
                            <li>Story main headline</li>
                            <li>Keyword</li>
                        </ul>
                    </ng-container>
                    <ng-template #content>
                        <ng-content></ng-content>
                    </ng-template>
                </clr-signpost-content>
            </clr-signpost>

            <input
                class="form-control clr-input c-searchbox__input"
                formControlName="search"
                name="search"
                type="text"
                [ngClass]="{ 'c-searchbox__black': showAdvancedOptions }"
                placeholder="{{ options.display.placeholder }}"
                size="55"
                id="{{ display.uniqueFormId }}-search-box-text" />

            <button
                type="submit"
                id="{{ display.uniqueFormId }}-search-box-btn"
                class="btn btn-link c-searchbox__button">
                <clr-icon shape="search" size="20" class="c-searchbox__button--icon"></clr-icon>
            </button>

            <button
                *ngIf="displayAdvancedOptionsInUI"
                id="{{ display.uniqueFormId }}-search-box-toggle"
                type="button"
                class="btn btn-link c-searchbox__button"
                (click)="toggleAdvancedOptions(); $event.preventDefault()">
                <clr-icon [attr.shape]="shape" size="16" class="c-searchbox__button--icon"></clr-icon>
            </button>
        </div>

        <!-- ADVANCED OPTIONS PANEL -->
        <div *ngIf="displayAdvancedOptionsInUI" class="u-display--background c-searchbox__popup">
            <div *ngIf="errorMessage !== ''" class="clr-row u-row--single">
                <div class="clr-col-sm-12 clr-col-md-12">
                    <div class="alert alert-danger">
                        <div class="alert-item">
                            <span class="alert-text">
                                {{ errorMessage }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <cms-progress-bar [isDisplayed]="!displayForm()" [isLoading]="true"></cms-progress-bar>

            <div *ngIf="displayForm()" class="c-searchbox__popup-content">
                <div class="clr-row">
                    <!-- CONTRIBUTOR -->
                    <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-12 clr-col-12" *ngIf="display.contributor">
                        <label for="{{ display.uniqueFormId }}-advancedSearchContributor" class="p4">Contributor</label>
                        <ng2-completer
                            id="{{ display.uniqueFormId }}-advancedSearchContributor"
                            [inputName]="'contributorName'"
                            (selected)="setContributor($event)"
                            [openOnClick]="true"
                            [datasource]="contributorsCompleterService"
                            [minSearchLength]="3"
                            [pause]="500"
                            formControlName="contributorName"
                            class="c-ng-completer u-input"></ng2-completer>

                        <div class="invalid" [hidden]="isValidContributor()">
                            <clr-icon class="red alert-icon" shape="error" size="24"></clr-icon>
                            <small>The contributor must be a valid contributor from the list.</small>
                        </div>
                    </div>

                    <!-- PUBLICATION OWNER -->
                    <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-12 clr-col-12" *ngIf="display.publicationOwner">
                        <label for="{{ display.uniqueFormId }}-advancedSearchPublicationOwner" class="p4"
                            >Publication Owner</label
                        >
                        <div class="form-control clr-select-wrapper">
                            <select
                                class="clr-select"
                                name="publicationOwner"
                                id="{{ display.uniqueFormId }}-advancedSearchPublicationOwner"
                                formControlName="publicationOwner">
                                <option
                                    *ngFor="let publicationOwner of publicationOwnerKeys"
                                    [ngValue]="publicationOwner">
                                    {{ publicationOwnerEnum[publicationOwner] }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- LANGUAGE -->
                    <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-12 clr-col-12" *ngIf="display.language">
                        <label for="{{ display.uniqueFormId }}-advancedSearchLanguage" class="p4">Language</label>
                        <div class="form-control clr-select-wrapper">
                            <select
                                class="clr-select"
                                name="languageId"
                                id="{{ display.uniqueFormId }}-advancedSearchLanguage"
                                formControlName="languageId">
                                <option [ngValue]="-1">All languages</option>
                                <option *ngFor="let lang of languages; let i = index" [ngValue]="lang.id">
                                    {{ lang.fullname }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- THEME -->
                    <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-12 clr-col-12" *ngIf="display.theme">
                        <label for="{{ display.uniqueFormId }}-advancedSearchTheme" class="p4">Theme</label>
                        <div class="form-control">
                            <cms-input-themes-select-list
                                class="clr-select"
                                [selectId]="display.uniqueFormId + '-advancedSearchTheme'"
                                formControlName="themeId"
                                name="themeId"
                                [firstOptionString]="'Any theme'">
                            </cms-input-themes-select-list>
                        </div>
                    </div>

                    <!-- TYPE -->
                    <div
                        class="clr-col-lg-6 clr-col-md-6 clr-col-sm-12 clr-col-12"
                        *ngIf="display.type && typeList && typeList.length">
                        <label for="{{ display.uniqueFormId }}-advancedSearchType" class="p4">Type</label>
                        <div class="form-control clr-select-wrapper">
                            <select
                                class="clr-select"
                                name="typeId"
                                id="{{ display.uniqueFormId }}-advancedSearchType"
                                formControlName="typeId">
                                <option [ngValue]="-1">Any type</option>
                                <option *ngFor="let type of typeList; let i = index" [ngValue]="type.id">
                                    {{ type.trans && type.trans.title ? type.trans.title : type.slug }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- PUBLICATION DATE -->
                    <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-12 clr-col-12" *ngIf="display.dateRange">
                        <label for="{{ display.uniqueFormId }}-advancedSearchDateRange" class="p4">Published</label>
                        <div class="form-control clr-select-wrapper">
                            <select
                                class="clr-select"
                                name="advancedSearchDateRange"
                                id="{{ display.uniqueFormId }}-advancedSearchDateRange"
                                formControlName="dateRange">
                                <option *ngFor="let dr of dateRangeValues; let i = index" [ngValue]="dr.value">
                                    {{ dr.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- PROGRAM -->
                    <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-12 clr-col-12" *ngIf="display.program">
                        <label for="{{ display.uniqueFormId }}-advancedSearchProgram" class="p4">Program</label>
                        <div class="form-control">
                            <cms-input-programs-select-list
                                class="clr-select"
                                [selectId]="display.uniqueFormId + '-advancedSearchProgram'"
                                formControlName="programId"
                                name="programId"
                                [firstOptionString]="'Any program'"
                                [isSelectableForSearch]="true">
                            </cms-input-programs-select-list>
                        </div>
                    </div>

                    <!-- VERTICAL -->
                    <div
                        class="clr-col-lg-6 clr-col-md-6 clr-col-sm-12 clr-col-12"
                        *ngIf="display.vertical && verticalList.length">
                        <label for="{{ display.uniqueFormId }}-advancedSearchVerticals" class="p4">Vertical</label>
                        <div class="form-control clr-select-wrapper overflowHidden">
                            <select
                                class="clr-select"
                                name="verticalId"
                                id="{{ display.uniqueFormId }}-advancedSearchVerticals"
                                formControlName="verticalId">
                                <option [ngValue]="-1">All verticals</option>
                                <option *ngFor="let vertical of verticalList; let i = index" [ngValue]="vertical.id">
                                    {{ vertical.translations[0].title }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- TAG -->
                    <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-12 clr-col-12" *ngIf="display.tag">
                        <label for="{{ display.uniqueFormId }}-advancedSearchTag" class="p4">Tag</label>
                        <ng2-completer
                            id="{{ display.uniqueFormId }}-advancedSearchTag"
                            [inputName]="'tagTitle'"
                            (selected)="setTag($event)"
                            [openOnClick]="true"
                            [datasource]="allTagsService"
                            [minSearchLength]="3"
                            [pause]="500"
                            formControlName="tagTitle"
                            placeholder="Enter English tag"
                            class="c-ng-completer u-input"></ng2-completer>

                        <div class="invalid" [hidden]="isValidTag()">
                            <clr-icon class="red alert-icon" shape="error" size="24"></clr-icon>
                            <small>The tag must be a valid tag from the list.</small>
                        </div>
                    </div>

                    <!-- STORIES OPTIONS -->
                    <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-12 clr-col-12" *ngIf="display.withVideoOnly">
                        <div class="clr-radio-wrapper firstRadio">
                            <input
                                type="radio"
                                formControlName="withVideoOnly"
                                id="{{ display.uniqueFormId }}-allStoriesRadio"
                                name="withVideoOnly"
                                [value]="videoSearchEnum.ALL_STORIES"
                                class="clr-radio" />
                            <label for="{{ display.uniqueFormId }}-allStoriesRadio">All stories</label>
                        </div>
                        <div class="clr-radio-wrapper">
                            <input
                                type="radio"
                                formControlName="withVideoOnly"
                                id="{{ display.uniqueFormId }}-withVideoRadio"
                                name="withVideoOnly"
                                [value]="videoSearchEnum.WITH_VIDEO"
                                class="clr-radio" />
                            <label for="{{ display.uniqueFormId }}-withVideoRadio">Stories with video</label>
                        </div>
                        <div class="clr-radio-wrapper">
                            <input
                                type="radio"
                                formControlName="withVideoOnly"
                                id="{{ display.uniqueFormId }}-withoutVideoRadio"
                                name="withVideoOnly"
                                [value]="videoSearchEnum.WITHOUT_VIDEO"
                                class="clr-radio" />
                            <label for="{{ display.uniqueFormId }}-withoutVideoRadio">Stories without video</label>
                        </div>
                        <div class="clr-radio-wrapper">
                            <input
                                type="radio"
                                formControlName="withVideoOnly"
                                id="{{ display.uniqueFormId }}-withGalleryImagesRadio"
                                name="withVideoOnly"
                                [value]="videoSearchEnum.WITH_GALLERY_IMAGES"
                                class="clr-radio" />
                            <label for="{{ display.uniqueFormId }}-withGalleryImagesRadio"
                                >Stories with gallery images</label
                            >
                        </div>
                    </div>

                    <!-- SUBMIT BUTTON -->
                    <div class="clr-col-12">
                        <button
                            type="submit"
                            [disabled]="disableSaveButton()"
                            class="btn btn-primary c-searchbox__button">
                            <clr-icon shape="search" size="16" class="c-searchbox__button--icon"></clr-icon> Search
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>
