import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ChangeDetectorRef,
} from '@angular/core'
import { FormControl, FormGroup, NonNullableFormBuilder } from '@angular/forms'
import { PublicationOwnerEnum } from 'enums/publication-owner'
import { AdvancedSearchDateRange } from 'enums/search-date-range'
import { StorySearchUniqueFormId } from 'enums/story-search'
import { VideoSearch } from 'enums/video-search'
import {
    AdvancedSearchBoxData,
    AdvancedSearchDisplayOptions,
    AdvancedSearchOptions,
} from 'models/advanced-search.model'
import { ContributorModel } from 'models/contributor.model'
import { LanguageModel } from 'models/language.model'
import { StoryType } from 'models/story.model'
import { VerticalModel } from 'models/vertical.model'
import { takeUntil } from 'rxjs/operators'
import { LanguagesService } from 'services/languages/languages'
import { AllContributorsData } from 'services/ng-completer/contributors.data'
import { AllTagsData } from 'services/ng-completer/tags.data'
import { StoryTypesListService } from 'services/story/types'
import { ThemesService } from 'services/themes/themes'
import { VerticalService } from 'services/verticals/verticals.service'
import { BaseAbstractComponent } from '../base.component'

interface SearchForm {
    search: FormControl<string>
    dateRange: FormControl<number>
    programId: FormControl<number>
    themeId: FormControl<number>
    typeId: FormControl<number>
    contributorName: FormControl<string>
    contributorId: FormControl<number>
    languageId: FormControl<number>
    publicationOwner: FormControl<number>
    withVideoOnly: FormControl<number>
    verticalId: FormControl<number>
    tagTitle: FormControl<string>
    tagId: FormControl<number>
}

/**
 * Display an article search component
 */
@Component({
    selector: 'cms-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent extends BaseAbstractComponent implements OnInit, OnDestroy {
    @Input() options: AdvancedSearchOptions
    @Input() showSignpost: boolean = true
    @Input() defaultSignpost: boolean = false
    @Output() lookup: EventEmitter<AdvancedSearchBoxData> = new EventEmitter()

    public searchForm: FormGroup<SearchForm>

    // Make the Enum available in the template hackity hack
    publicationOwnerEnum: any = PublicationOwnerEnum
    videoSearchEnum: any = VideoSearch

    // The options to display stuff in the U.I.
    public display: AdvancedSearchDisplayOptions = {
        uniqueFormId: '' as StorySearchUniqueFormId,
        contributor: false,
        publicationOwner: false,
        language: false,
        dateRange: false,
        program: false,
        placeholder: 'Search',
        theme: false,
        type: false,
        withVideoOnly: false,
        vertical: false,
        tag: false,
    }

    // Current date ranges we can pass to the search service
    protected dateRangeValues: any = [
        { value: 0, label: 'At any time' },
        { value: 7, label: 'In the past week' },
        { value: 31, label: 'In the past month' },
        { value: 365, label: 'In the past year' },
        { value: -1, label: 'In the future' },
    ]
    // The contributor used in search
    protected contributor: ContributorModel
    // Lets us create a selectlist using the Channels Enum
    protected publicationOwnerKeys = Object.keys(PublicationOwnerEnum)
        .filter(Number)
        .map(key => +key)
    // Show/Hide the advanced dropdown
    showAdvancedOptions: boolean = false
    // Shape to use within the clr-icon (fixes chrome bug when using *ngIf)
    protected shape: string = 'caret down'
    // Do we need to display the "advanced options" button in the template
    displayAdvancedOptionsInUI = false

    // Theme list to use in the template
    protected themeList: any[] = []

    // Story Type to use in the template
    protected typeList: StoryType[] = []

    // Verticals to use in template
    protected verticalList: VerticalModel[] = []

    // Used to display loading indicators
    public themesLoaded: boolean = true
    public typesLoaded: boolean = true
    public verticalsLoaded: boolean = true

    public languages: LanguageModel[]

    // Some error messages we may have to display within the popunder form
    protected errorMessage: string = ''
    protected messages: string[] = [
        'Yikes, there was an error loading theme data from the server…',
        'Yikes, there was an error loading program data from the server…',
        'Yikes, there was an error loading story types data from the server…',
    ]

    // @TODO Remove the ng-completer hack regExp test
    // Host listener to catch clicks outside of the advanced searchbox and close the panel if it's open
    @HostListener('document:click', ['$event'])
    clickout(event) {
        event.stopPropagation()

        if (
            !this.showAdvancedOptions ||
            this.eRef.nativeElement.contains(event.target) ||
            /completer/.test(event.target.className)
        ) {
            return
        }

        this.toggleAdvancedOptions()
    }

    constructor(
        private eRef: ElementRef,
        private themeService: ThemesService,
        private storyTypesListService: StoryTypesListService,
        private verticalService: VerticalService,
        private languagesService: LanguagesService,
        public contributorsCompleterService: AllContributorsData,
        public allTagsService: AllTagsData,
        private cd: ChangeDetectorRef,
        protected formBuilder: NonNullableFormBuilder,
    ) {
        super()
        this.languages = this.languagesService.languages
    }

    public ngOnInit(): void {
        // What do we need to display in the U.I.
        const displayDefaults: string[] = [
            'contributor',
            'dateRange',
            'program',
            'language',
            'theme',
            'type',
            'publicationOwner',
            'withVideoOnly',
            'vertical',
            'tag',
        ]

        this.display.uniqueFormId = this.options.display.uniqueFormId
        this.display.placeholder = Object.prototype.hasOwnProperty.call(this.options.display, 'placeholder')
            ? this.options.display.placeholder
            : 'Search…'

        displayDefaults.forEach(v => {
            this.display[v] = this.getDisplayValue(v)
            this.displayAdvancedOptionsInUI = this.displayAdvancedOptionsInUI || this.display[v]
        })

        if (this.display.theme) {
            this.themesLoaded = false
        }

        if (this.display.type) {
            this.typesLoaded = false
        }

        if (this.display.vertical) {
            this.verticalsLoaded = false
        }

        // Set sensible default values if none passed in
        this.searchForm = this.formBuilder.group({
            search: [this.getDefaultValue('search') || ''],
            dateRange: [this.getDefaultValue('dateRange') || AdvancedSearchDateRange.AT_ANY_TIME],
            programId: [this.getDefaultValue('programId') || -1],
            themeId: [this.getDefaultValue('themeId') || -1],
            typeId: [this.getDefaultValue('typeId') || -1],
            contributorName: [this.getDefaultValue('contributorName') || ''],
            contributorId: [this.getDefaultValue('contributorId') || -1],
            languageId: [this.getDefaultValue('languageId') || -1],
            publicationOwner: [this.getDefaultValue('publicationOwner') || PublicationOwnerEnum.Euronews],
            withVideoOnly: [this.getDefaultValue('withVideoOnly') || VideoSearch.ALL_STORIES],
            verticalId: [this.getDefaultValue('verticalId') || -1],
            tagTitle: [this.getDefaultValue('tagTitle') || ''],
            tagId: [this.getDefaultValue('tagId') || -1],
        })
    }

    /**
     * Grabs the value from the `@Input` object
     */
    private getDefaultValue(key: string): any {
        return this.options?.defaults?.[key]
    }

    /**
     * Grabs the value from the `@Input` object
     */
    private getDisplayValue(key: string): boolean {
        return (
            this.options &&
            Object.prototype.hasOwnProperty.call(this.options, 'display') &&
            this.options.display &&
            Object.prototype.hasOwnProperty.call(this.options.display, key) &&
            this.options.display[key]
        )
    }

    public ngOnDestroy(): void {
        this.cd.detach()
        super.ngOnDestroy()
    }

    /**
     * Emit searchBoxData to encapsulating component
     */
    public search(): void {
        if (this.showAdvancedOptions) {
            this.toggleAdvancedOptions()
        }

        const searchBoxData: AdvancedSearchBoxData = {
            ...this.searchForm.value,
        }

        searchBoxData.search = searchBoxData.search!.trim()

        this.lookup.emit(searchBoxData)
    }

    /**
     * Show/Hide the advanced options dropdown and load the program and/or theme lists if required
     */
    public toggleAdvancedOptions(): void {
        this.showAdvancedOptions = !this.showAdvancedOptions
        if (this.showAdvancedOptions) {
            this.shape = 'caret up'
            if (this.display.theme && !this.themeList.length) {
                this.loadThemeList()
            }
            if (this.display.type && !this.typeList.length) {
                this.loadTypeList()
            }
            if (this.display.vertical && !this.verticalList.length) {
                this.loadVerticalList()
            }
            if (this.display.publicationOwner) {
                this.searchForm.patchValue({
                    publicationOwner: this.getDefaultValue('publicationOwner') || PublicationOwnerEnum.Euronews,
                })
            }
        } else {
            this.shape = 'caret down'
        }
    }

    public displayForm(): boolean {
        return this.errorMessage === '' && this.themesLoaded && this.typesLoaded && this.verticalsLoaded
    }

    /**
     * Loads the themes for the search interface
     */
    private loadThemeList(): void {
        this.themesLoaded = false
        this.themeService.getAllThemes()
        this.themeService.themes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            themes => {
                this.themeList = themes
                    .map(d => {
                        let themeEntry
                        if (d.translations && d.translations.length) {
                            themeEntry = d.translations.reduce((o, v) => {
                                if (v?.slug && v?.language.isoCode === this.languagesService.defaultLanguage.isoCode) {
                                    return v
                                }

                                return o
                            })
                        }

                        return {
                            id: d.id,
                            slug: d.slug,
                            title:
                                themeEntry !== undefined &&
                                Object.prototype.hasOwnProperty.call(themeEntry, 'title') &&
                                themeEntry['title']
                                    ? themeEntry.title
                                    : d.slug,
                        }
                    })
                    .sort((a: any, b: any) => {
                        const aLower = a.title.toLowerCase()
                        const bLower = b.title.toLowerCase()

                        return aLower < bLower ? -1 : aLower > bLower ? 1 : 0
                    })

                this.themesLoaded = true
                this.cd.markForCheck()
            },
            _ => {
                this.errorMessage = this.messages[0]
            },
        )
    }

    /**
     * Loads the story types for the search interface
     */
    private loadTypeList(): void {
        this.typesLoaded = false
        this.storyTypesListService.storyTypes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            storyTypes => {
                this.typeList = storyTypes
                this.typesLoaded = true
                this.cd.markForCheck()
            },
            _ => {
                this.errorMessage = this.messages[2]
            },
        )
        this.storyTypesListService.loadStoryTypes()
    }

    /**
     * Loads the verticals for the search interface.
     */
    private loadVerticalList(): void {
        this.verticalsLoaded = false

        this.verticalService.verticals.pipe(takeUntil(this.ngUnsubscribe)).subscribe(list => {
            this.verticalsLoaded = true
            this.verticalList = list.filter(el => {
                return el.translations.length > 0
            })
        })

        this.verticalService.getList()
    }

    public disableSaveButton(): boolean {
        return this.searchForm.invalid || !this.isValidContributor()
    }

    public isValidContributor(): boolean {
        return (
            // Contributor name and id are both set
            (this.searchForm.controls.contributorName.value.trim() &&
                this.searchForm.controls.contributorId.value !== -1) ||
            // Contributor name and id are both empty
            (this.searchForm.controls.contributorName.value.trim() === '' &&
                this.searchForm.controls.contributorId.value === -1)
        )
    }

    public setContributor(args): void {
        this.searchForm.patchValue({
            contributorId: (args && args.originalObject && args.originalObject.id) || -1,
        })
    }

    public isValidTag(): boolean {
        return (
            // Tag title and id are both set
            (this.searchForm.controls.tagTitle.value.trim() && this.searchForm.controls.tagId.value !== -1) ||
            // Tag title and id are both empty
            (this.searchForm.controls.tagTitle.value.trim() === '' && this.searchForm.controls.tagId.value === -1)
        )
    }

    public setTag(args): void {
        this.searchForm.patchValue({
            tagId: (args && args.originalObject && args.originalObject.id) || -1,
        })
    }
}
